<template>
  <div class="yimiao">
    <van-sticky>
      <van-notice-bar left-icon="volume-o"
        text="点击人员信息可直接进行疫苗接种登记，点击下方 “ 登记 ” 按钮则需输入基本信息进行疫苗登记。" />
    </van-sticky>

    <div style="height: 100vh;">
      <div style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        ">
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%"
            src="../../../assets/疫苗.png"
            alt="" />
        </div>
        <div style="
            display: inline-block;
            position: absolute;
            top: 30px;
            left: 100px;
          ">
          <div style="font-size: 18px">疫苗接种登记</div>
        </div>
      </div>
      <div style="padding-bottom: 90px;">
        <!-- <van-cell-group>
          <van-cell-group>
            <van-field v-model="datafrom.idCard"
              label="身份证号"
              required
              placeholder="请输入身份证号码" />
          </van-cell-group>
          <van-field v-model="datafrom.name"
            label=" 姓 名 "
            required
            placeholder="请输入姓名" />
        </van-cell-group> -->

        <!-- 全部 -->
        <div class="center"
          v-for="item in list"
          :key="item.index"
          @click="add(item.HId)"
          style="
            border: 1px solid rgb(222 206 206);
            width: 95%;
            margin:5px auto;
            border-radius: 5px;
          ">
          <div class="Content"
            style="padding: 8px 10px">
            <div style="display: inline-block">
              <span class="centertime"
                style="color: #333; font-size: 17px">
                姓名：{{ item.Name }}
              </span>
              <div style="font-size: 17px; color: #333; margin: 5px 0">
                身份证号：{{ item.IdNumber }}
              </div>
              <div style="font-size: 17px; color: #333; margin: 5px 0">
                联系电话：{{ item.MobileTel }}
              </div>
              <div style="font-size: 17px; color: #333; margin: 5px 0">
                {{ item.OrganName }}-{{ item.RName }}-{{ item.BName }}-{{ item.BUName }}单元-{{ item.BFNO }}层-{{ item.BRNO }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <van-button type="info"
          style="margin: 10px 0;border:0;"
          @click="addRegister()">接种登记</van-button>
        <!-- <van-button style="border: 1px solid #e6e6e6; margin: 10px 0"
          type="
          default"
          @click="fanhui()">返回</van-button> -->
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { NoticeBar } from 'vant';
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
Vue.use(Toast);
import { WeGetMyFaResidentList } from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      datafrom: {
        name: "",
        idCard: "",
      },
      list: [],
    };
  },
  created () {
    this.getMyFaResidentList()
  },
  methods: {
    //获取我的家庭成员信息
    getMyFaResidentList () {
      var OpenID = getOpenId();
      // var OpenID = '111';
      WeGetMyFaResidentList({ openID: OpenID }).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data == undefined) {
            this.list = [];
            Dialog.alert({
              title: "提示",
              message: "未找到家庭成员信息，请点击确定进行登记",
            }).then(() => {
              this.$router.push({
                path: "/register/fangyi/yimiaoNew/ymShouye/ymWu",
              });
            });
          } else {
            this.list = res.data.data;
          }
        }
      })
    },
    postfrom: function () {
      // this.datafrom.OpenID = getOpenId();
      this.datafrom.OpenID = '111';
      WxSearchResident(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          this.list = res.data.data;
          if (this.list.length == "1") {
            this.$router.push({
              path: "/register/fangyi/yimiaoNew/ymShouye/ymYou/" + this.list[0].HId,
            });
          } else if (this.list.length > "1") {
            Dialog.alert({
              title: "提示",
              message: "操作成功，请选择需要报备的人员信息",
            }).then(() => {
              // this.$router.go(-1);
              // this.$router.push({
              //   name: "register",
              // });
            });
          } else {
            Dialog.confirm({
              title: "提示",
              message: "没有找到人员信息，可点击确定进行出淇人员报备",
            })
              .then(() => {
                console.log(this.datafrom);
                if (this.datafrom.idCard == '') {
                  this.$router.push({
                    path: "/register/fangyi/yimiaoNew/ymShouye/ymWu/" + this.datafrom.name + '/' + undefined,
                  });
                } else {
                  this.$router.push({
                    path: "/register/fangyi/yimiaoNew/ymShouye/ymWu/" + this.datafrom.name + '/' + this.datafrom.idCard,
                  });
                }

              })
              .catch(() => {
              });
          }
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },

    add (hid) {
      this.$router.push({
        path: "/register/fangyi/yimiaoNew/ymShouye/ymYou/" + hid,
        // query: { hid: hid },
      });
    },
    addRegister (hid) {
      this.$router.push({
        path: "/register/fangyi/yimiaoNew/ymShouye/ymWu",
      });
    },
    fanhui: function () {
      this.$router.go(-1)
      // this.$router.push({
      //   name: "register",
      // });
    },
  },
};
</script>
<style scoped>
.imgs {
  margin: 0 15px;
}
.yimiao .inputFrom {
  margin: 15px 8%;
}
.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.yimiao .buttons {
  text-align: center;
}
.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}
.buttons {
  text-align: center;
  position: fixed;
  bottom: 0px;
  width: 95%;
  background: white;
  border: 10px solid white;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>